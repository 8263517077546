<script lang="ts">
import { trackCustomer } from '@igefa-e-commerce/buy-app-utilities/dist/tracking/trackCustomer'
import {
  faUser,
  faUserCheck,
} from '@igefa-e-commerce/pattern-library-icons/fa/pro-solid-svg-icons'
import Button from '@igefa-e-commerce/pattern-library/src/components/Button/Button.svelte'
import type { Customer } from 'domain/model/Customer.ts'
import { onDestroy, onMount } from 'svelte'
import {
  USER_ACCOUNT_CREATED_AND_USER_LOGGED_IN_EVENT,
  USER_ACCOUNT_CREATED_BROADCAST_CHANNEL,
} from '../../../consts.ts'
import { pages } from '../../../pages.ts'

export let loggedIn: boolean
export let customer: Customer | undefined = undefined

const channel = new BroadcastChannel(USER_ACCOUNT_CREATED_BROADCAST_CHANNEL)

const handleChannelMessage = ({ data: message }: MessageEvent) => {
  if (message === USER_ACCOUNT_CREATED_AND_USER_LOGGED_IN_EVENT) {
    loggedIn = true
  }
}

const redirect = () => {
  if (!loggedIn) {
    window.location.href = pages.user.login()
  } else {
    window.location.href = pages.user.myAccount()
  }
}

onMount(() => {
  if (customer) {
    trackCustomer({
      customerNumber: customer.customerNumber,
      email: customer.email,
      customerCategory: customer.category,
    })
  }

  if (typeof window !== 'undefined') {
    channel.addEventListener('message', handleChannelMessage)
  }
})

onDestroy(() => {
  if (typeof window !== 'undefined') {
    channel.removeEventListener('message', handleChannelMessage)
  }
})
</script>

<div>
  <div on:click={redirect} class="icon" data-testid="my-account-icon">
    <Button class="my-account-icon" icon={ loggedIn ? faUserCheck : faUser } iconType="top" variant="tertiary" />
  </div>
</div>

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/fonts.scss';
  @use '@igefa-e-commerce/pattern-library/src/css/spacers.scss';

  .icon {
    position: relative;
    width: fit-content;
    height: fit-content;
  }
</style>
